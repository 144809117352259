import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const AboutMe = () => {
  const { t } = useTranslation();
  const textDirection = t("common.text-direction");
  return (
    <div className="flex items-center justify-center min-h-full p-1 md:h-full md:p-3 lg:p-5 xl:p-7 text-shadow-md">
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 0.4,
        }}
        style={{ direction: textDirection }}
        className="flex flex-col items-center justify-between h-full gap-5 px-2 py-3 text-center rounded-md lg:overflow-y-scroll scrollbar-hide md:gap-10 lg:gap-2 md:px-10 dark:text-white bg-white dark:bg-secondary shadow-md xl:py-6 xl:px-5 font-edusa md:text-lg lg:text-lg xl:text-2xl"
      >
        <div className="flex flex-col justify-center flex-grow md:gap-3 lg:gap-1">
          <h2 className="font-semibold text-rose-500 dark:text-highlight">
            {t("about.about.greeting")}{" "}
          </h2>
          <p>{t("about.about.hope")}</p>
        </div>

        <div className="flex flex-col justify-between flex-grow gap-2 lg:gap-0">
          <div className="leading-7 md:leading-10 lg:leading-8">
          {t("about.about.my")} <span className="font-semibold">{t("about.about.name")}</span>{" "}
          {t("about.about.pursu")}{" "}
            <span className="font-semibold">
            {t("about.about.btech")}
            </span>{" "}
            {t("about.about.from")} <span className="font-semibold">{t("about.about.tiips")}</span>
          </div>
          <div className="leading-7 md:leading-10 lg:leading-8">
          {t("about.about.interest")}{" "}
            <span className="font-semibold">{t("about.about.enth")}</span> {t("about.about.and")}{" "}
            <span className="font-semibold">{t("about.about.eager")}</span> {t("about.about.believe")}
          </div>
          <div className="leading-7 md:leading-10 lg:leading-8">
          {t("about.about.quit")}{" "}
            <span className="font-semibold">{t("about.about.spirit")}</span> {t("about.about.i")}{" "}
            <span className="font-semibold">{t("about.about.afraid")}</span>{" "}
            {t("about.about.instead")}
          </div>
        </div>

        <div className="flex flex-col justify-center flex-grow gap-1 text-rose-500 dark:text-highlight">
          <div className="font-semibold">{t("about.about.regards")}</div>
          <div className="font-semibold">{t("about.about.name")}</div>
        </div>
      </motion.div>
    </div>
  );
};

export default AboutMe;

// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './translations/en.json';
import hiTranslation from './translations/hi.json'
import jaTranslation from './translations/ja.json';
import esTranslation from './translations/es.json';
import deTranslation from './translations/de.json';
import arTranslation from './translations/ar.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      hi: { translation: hiTranslation },
      ja: { translation: jaTranslation },
      es: { translation: esTranslation },
      de: { translation: deTranslation },
      ar: { translation: arTranslation },
      // Add more languages as needed
    },
    lng: 'en', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const navClassName =
  "flex text-lg text-gray-700 dark:text-gray-200 py-2 font-semibold lg:p-0 flex-col justify-center items-center cursor-pointer hover:text-black hover:scale-105 duration-100 hover:border-b border-b-black dark:border-b-gray-200 ease-linear select-none";
const activeNavClassName =
  "flex text-xl font-bold py-2 lg:p-0 flex-col text-rose-500 dark:text-highlight justify-center items-center cursor-pointer border-b border-b-rose-500 dark:border-highlight sm:hover:scale-105 duration-100 ease-linear select-none";

const NavItem = (props) => {
  const { t } = useTranslation();
  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        isActive ? activeNavClassName : navClassName
      }
    >
      {props.icon}
      <span className={`text-xs mt-0.5 select-none`}>
        {t("navbar." + props.text)}
      </span>
    </NavLink>
  );
};

export default NavItem;

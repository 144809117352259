import React from "react";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion";

const Modal = (props) => {
  return (
    <div className="absolute flex items-center justify-center left-0 w-screen h-screen z-30">
      <div
        onClick={props.onClose}
        className="fixed inset-0 z-10 flex justify-center w-screen h-screen bg-black opacity-60"
      ></div>
      {props.orientation === 0 && (
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: 0.5,
          }}
          exit={{ y: -20, opacity: 0 }}
          className="relative z-20 p-1 overflow-hidden bg-white dark:bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-highlight via-tertiary to-highlight rounded-sm w-fit lg:rounded-lg"
        >
          <div className="w-full h-1/2">
            <img
              src={props.painting}
              alt=""
              className="h-[65vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] rounded-sm lg:rounded-lg"
            />
            <div
              onClick={props.onClose}
              className="absolute p-1 rounded-full shadow-lg cursor-pointer border border-rose-500 bg-white text-rose-500 dark:border-primary dark:bg-highlight dark:text-primary dark:hover:border-highlight dark:hover:bg-primary dark:hover:text-highlight hover:text-white hover:border-white hover:bg-rose-500 top-2 right-2"
            >
              <IoClose className="text-lg lg:text-3xl" />
            </div>
          </div>
        </motion.div>
      )}
      {props.orientation === 1 && (
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: 0.5,
          }}
          exit={{ y: -20, opacity: 0 }}
          className="relative w-[95%] md:w-[90%] lg:w-5/6 z-20 p-1 overflow-hidden bg-white dark:bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-highlight via-tertiary to-highlight rounded-sm lg:rounded-lg"
        >
          <div className="w-full h-1/2">
            <img
              src={props.painting}
              alt=""
              className="aspect-video rounded-sm lg:rounded-lg"
            />
            <div
              onClick={props.onClose}
              className="absolute p-1 rounded-full shadow-lg cursor-pointer border border-rose-500 bg-white text-rose-500 dark:border-primary dark:bg-highlight dark:text-primary dark:hover:border-highlight dark:hover:bg-primary dark:hover:text-highlight hover:text-white hover:border-white hover:bg-rose-500 top-2 right-2"
            >
              <IoClose className="text-lg lg:text-3xl" />
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Modal;

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Main from "./components/Main/Main";
import Navbar from "./components/Navbar/Navbar";
import Portfolio from "./components/Portfolio/Portfolio";
import Skills from "./components/Skills/Skills";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import NotFound from "./components/UI/NotFound";
import { useEffect, useState } from "react";
import { FiSun, FiMoon } from "react-icons/fi";
import LanguageSelector from "./components/UI/LanguageSelector";

function App() {
    const location = useLocation();
    let isDarkTheme = localStorage.getItem("mohitPortfoliotTheme") !== "light"; // Default to dark
    const [isDarkMode, setIsDarkMode] = useState(isDarkTheme);

    // Function to toggle dark mode
    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    // Watch for changes in isDarkMode and add/remove the 'dark' class accordingly
    useEffect(() => {
        const rootElement = document.documentElement;
        const lightThemeLink = document.getElementById("light-theme");
        const darkThemeLink = document.getElementById("dark-theme");

        if (isDarkMode) {
            rootElement.classList.add("dark");
            localStorage.setItem("mohitPortfoliotTheme", "dark");
        } else {
            rootElement.classList.remove("dark");
            localStorage.setItem("mohitPortfoliotTheme", "light");
        }

        // Enable/Disable stylesheets based on theme
        if (lightThemeLink && darkThemeLink) {
            lightThemeLink.disabled = isDarkMode;
            darkThemeLink.disabled = !isDarkMode;
        }
    }, [isDarkMode]);

    return (
        <div className="flex flex-col items-center overflow-x-hidden antialiased dark:bg-primary bg-slate-100">
            <Navbar />
            <div className="fixed hidden lg:block top-5 right-24 z-30">
                <LanguageSelector />
            </div>
            <button
                className="fixed hidden lg:block hover:shadow-md hover:scale-105 duration-200 z-30 top-5 right-5 p-3 rounded-full bg-rose-500 dark:bg-secondary text-white dark:text-gray-200"
                onClick={toggleDarkMode}
            >
                {isDarkMode ? <FiSun size={20} /> : <FiMoon size={20} />}
            </button>
            <AnimatePresence mode="wait">
                <Routes key={location.pathname} location={location}>
                    <Route
                        path="/"
                        element={
                            <Main
                                toggleDarkMode={toggleDarkMode}
                                isDarkMode={isDarkMode}
                            />
                        }
                    />
                    <Route path="/about" element={<About />} />
                    <Route path="/skills" element={<Skills />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </AnimatePresence>
        </div>
    );
}

export default App;

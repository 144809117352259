import React from "react";
import {
  BiHome,
  BiUser,
  BiBook,
  BiBriefcaseAlt,
  BiMessageSquareDetail,
} from "react-icons/bi"

import NavItem from "./NavItem";

const Navitems = [
  {
    id: "ni1",
    icon: <BiHome/>,
    text: "home",
    to: "/",
  },
  {
    id: "ni2",
    icon: <BiUser/>,
    text: "about",
    to: "about",
  },
  {
    id: "ni3",
    icon: <BiBook/>,
    text: "skills",
    to: "skills",
  },
  {
    id: "ni4",
    icon: <BiBriefcaseAlt />,
    text: "portfolio",
    to: "portfolio",
  },
  {
    id: "ni5",
    icon: <BiMessageSquareDetail />,
    text: "contact-me",
    to: "contact",
  },
];

const Navbar = () => {
  return (
    <>
      <nav className="flex md:p-1.5 shadow-md dark:lg:border-b dark:lg:border-r border-tertiary dark:bg-secondary bg-white fixed bottom-0 lg:bottom-auto lg:w-2/4 mx-auto rounded-t-md lg:rounded-full justify-around z-10 mt-4 w-screen ">
        {Navitems.map((Navitem) => (
          <NavItem
            key={Navitem.id}
            icon={Navitem.icon}
            text={Navitem.text}
            to={Navitem.to}
          />
        ))}
      </nav>
    </>
  );
};

export default Navbar;

import React from "react";
import { motion } from "framer-motion";
import { fadeInItem } from "../../../utils/motion";

const WorkItem = (props) => {
    return (
        <>
            <motion.li
                variants={fadeInItem}
                whileHover={{
                    scale: 1.02,
                    transition: { duration: 0.2 },
                }}
                className="flex p-3 py-5 text-left rounded-lg shadow-lg cursor-default font-nunito lg:justify-between bg-white dark:bg-secondary dark:text-gray-200 lg:px-5 lg:py-6"
            >
                <div className="lg:flex-grow lg:w-3/5 xl:w-auto">
                    <div className="flex justify-between">
                        <div>
                            <h2 className="text-lg font-bold leading-tight font-quicksand lg:leading-normal lg:text-xl dark:text-gray-100">
                                {props.jobRole}
                            </h2>
                            <h2 className="text-base font-semibold leading-tight lg:leading-normal font-quicksand">
                                {props.company}
                            </h2>
                            <p className="text-sm font-semibold lg:text-sm">
                                {props.location}
                            </p>
                            <p className="text-xs font-bold lg:hidden font-quicksand lg:text-base ">
                                {props.duration}
                            </p>
                        </div>
                        <div className="hidden text-center lg:block">
                            <p className="px-3 text-white py-1 text-xs font-bold rounded-full dark:text-primary bg-rose-500 dark:bg-highlight font-nunito lg:text-xs xl:text-sm">
                                {props.duration}
                            </p>
                        </div>
                    </div>
                    <ul className="my-3">
                        {props.responsibilities.map((responsibility) => (
                            <li
                                key={responsibility}
                                className="mt-1 ml-4 text-sm text-slate-800 dark:text-gray-200 font-medium leading-snug list-disc font-quicksand lg:w-11/12 lg:text-sm lg:font-semibold"
                            >
                                {responsibility}
                            </li>
                        ))}
                    </ul>
                    <div className="flex flex-wrap gap-2 pt-2 lg:w-5/6">
                        {props.skills.map((skill) => (
                            <span
                                key={skill}
                                className="p-1 px-2 text-xs text-white dark:text-primary font-semibold duration-200 rounded-md select-none font-roboto bg-opacity-90 bg-rose-500 dark:bg-highlight hover:scale-110"
                            >
                                {skill}
                            </span>
                        ))}
                    </div>
                </div>
            </motion.li>
        </>
    );
};

export default WorkItem;

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { IoMdMail } from "react-icons/io";
import {
    IoLocationSharp,
    IoLogoFacebook,
    IoLogoGithub,
    IoLogoLinkedin,
    IoLogoInstagram,
} from "react-icons/io5";
import { SiBuymeacoffee } from "react-icons/si";
import { motion } from "framer-motion";

import Notification from "../UI/Notification";
// import Footer from "../Footer/Footer";
import AnimatedPage from "../UI/AnimatedPage";
import { useTranslation } from "react-i18next";

const Contact = () => {
    const { t } = useTranslation();
    const textDirection = t("common.text-direction");
    const form = useRef();
    const [messageType, setMessageType] = useState(true);
    const [messageSent, setMessageSent] = useState(false);

    const closeMessageHandler = () => {
        setMessageSent(false);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_5hfglft",
                "template_osakpbx",
                form.current,
                "d91rZ-E8b5MbiG0QL"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setMessageType(true);
                },
                (error) => {
                    console.log(error.text);
                    setMessageType(false);
                }
            );
        form.current.user_name.value = "";
        form.current.user_email.value = "";
        form.current.message.value = "";

        setMessageSent(true);
        setTimeout(() => {
            setMessageSent(false);
        }, 8000);
    };

    return (
        <AnimatedPage>
            <section
                style={{ direction: textDirection }}
                id="contact"
                className="relative flex flex-col items-center justify-center w-full md:min-h-screen"
            >
                {messageSent && (
                    <Notification
                        messageClass={messageType}
                        onCloseMessage={closeMessageHandler}
                    />
                )}
                <div className="flex flex-col w-full p-8 pb-[10vh] space-y-8 shadow-lg lg:flex-row lg:gap-x-6 lg:space-y-0 dark:text-white bg-slate-100 lg:bg-white dark:bg-primary md:bg-white dark:md:bg-secondary md:p-5 lg:py-5 lg:px-8 lg:mt-5 xl:mt-0 md:max-w-2xl lg:max-w-4xl xl:max-w-5xl sm:p-6 md:rounded-xl xl:p-10 xl:py-14 bg-opacity-90 backdrop-blur-lg">
                    <div className="flex flex-col justify-between space-y-4 md:space-y-8 lg:space-y-0">
                        <div>
                            <h1 className="text-4xl font-bold tracking-wide">
                                {t("contact.contact-me")}
                            </h1>
                            <p className="pt-4 text-sm">{t("contact.desc")}</p>
                            <a
                                href="https://www.buymeacoffee.com/mohitthapliyal"
                                target="buymeacoffee"
                                className="inline-flex text-white items-center px-2 py-1 mt-2 gap-x-2 duration-300 rounded-md bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-red-500 dark:from-tertiary via-pink-500 dark:via-highlight to-rose-500 dark:to-tertiary xl:mt-4 sm:hover:scale-110"
                            >
                                <SiBuymeacoffee className="text-2xl dark:text-secondary" />
                                <span className="font-semibold font-edusa dark:text-secondary">
                                    {t("contact.buy")}
                                </span>
                            </a>
                        </div>
                        <div className="flex flex-col space-y-1 sm:space-y-6">
                            <div className="transition-all duration-300 ease-in-out cursor-pointer w-fit hover:scale-110">
                                <a
                                    href="mailto:mohitthapliyal100@gmail.com"
                                    className="flex items-center gap-x-2"
                                >
                                    <IoMdMail className="text-xl text-rose-500 dark:text-highlight" />
                                    <span>mohitthapliyal100@gmail.com</span>
                                </a>
                            </div>
                            <div className="transition-all duration-300 ease-in-out cursor-pointer w-fit hover:scale-110">
                                <p
                                    className="flex items-center gap-x-2"
                                    href=""
                                >
                                    <IoLocationSharp className="text-xl text-rose-500 dark:text-highlight" />
                                    <span>{t("contact.place")}</span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="flex gap-4 text-xl">
                                <a
                                    target="facebook"
                                    href="https://www.facebook.com/profile.php?id=100010237621145"
                                    className="transition-all duration-300 ease-in-out hover:text-rose-500 dark:hover:text-highlight hover:scale-150"
                                >
                                    <IoLogoFacebook />
                                </a>
                                <a
                                    target="github"
                                    href="https://github.com/Mohit-Thapliyal"
                                    className="transition-all duration-300 ease-in-out hover:text-rose-500 dark:hover:text-highlight hover:scale-150"
                                >
                                    <IoLogoGithub />
                                </a>
                                <a
                                    target="linkedin"
                                    href="https://www.linkedin.com/in/mohit-thapliyal-b20104146/"
                                    className="transition-all duration-300 ease-in-out hover:text-rose-500 dark:hover:text-highlight hover:scale-150"
                                >
                                    <IoLogoLinkedin />
                                </a>
                                <a
                                    target="instagram"
                                    href="https://www.instagram.com/mohitthapliyal100/"
                                    className="transition-all duration-300 ease-in-out hover:text-rose-500 dark:hover:text-highlight hover:scale-150"
                                >
                                    <IoLogoInstagram />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <motion.div
                            initial={{ y: 40, opacity: 0 }}
                            animate={{
                                y: 0,
                                opacity: 1,
                            }}
                            transition={{
                                duration: 0.5,
                            }}
                            className="sm:p-5 py-3 px-0  dark:bg-primary md:px-10 md:shadow-lg md:bg-slate-200 rounded-xl lg:p-3 lg:px-5 xl:p-9 lg:w-96"
                        >
                            <form
                                ref={form}
                                onSubmit={sendEmail}
                                className="flex flex-col space-y-4"
                            >
                                <div>
                                    <label
                                        htmlFor="contact-name"
                                        className="text-sm font-semibold text-shadow-lg"
                                    >
                                        {t("contact.name")}
                                    </label>
                                    <input
                                        id="contact-name"
                                        name="user_name"
                                        type="text"
                                        placeholder={t("contact.e-name")}
                                        className="w-full px-4 py-2 mt-2 rounded-md outline-none ring-1 ring-gray-300 focus:ring-2 focus:ring-rose-500 dark:focus:ring-highlight dark:bg-secondary dark:ring-secondary"
                                        required
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="contact-email"
                                        className="text-sm font-semibold text-shadow-lg"
                                    >
                                        {t("contact.mail")}
                                    </label>
                                    <input
                                        id="contact-email"
                                        name="user_email"
                                        type="email"
                                        placeholder={t("contact.e-mail")}
                                        className="w-full px-4 py-2 mt-2 rounded-md outline-none ring-1 ring-gray-300 focus:ring-2 focus:ring-rose-500 dark:focus:ring-highlight dark:bg-secondary dark:ring-secondary"
                                        required
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="contact-msg"
                                        className="text-sm font-semibold text-shadow-lg"
                                    >
                                        {t("contact.msg")}
                                    </label>
                                    <textarea
                                        id="contact-msg"
                                        name="message"
                                        rows="4"
                                        type="text"
                                        placeholder={t("contact.w-msg")}
                                        className="w-full px-4 py-2 mt-2 rounded-md outline-none ring-1 ring-gray-300 focus:ring-2 focus:ring-rose-500 dark:focus:ring-highlight dark:bg-secondary dark:ring-secondary"
                                        required
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="px-4 py-2 text-sm font-bold uppercase dark:text-primary rounded-lg bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-red-500 via-pink-500 to-rose-500 dark:from-highlight dark:to-highlight text-white transition-all duration-300 ease-in-out hover:scale-105 hover:scale-y-110"
                                >
                                    {t("contact.send")}
                                </button>
                            </form>
                        </motion.div>
                    </div>
                </div>
            </section>
        </AnimatedPage>
    );
};

export default Contact;
